import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// (function ($, window, document) {
  $(document).ready(function () {
    var $window = $(window)
    var $body = $("body")

    function initOwlSliders(slider, items, lazyLoad, autoplay, nav, dots) {
      let $owl = $(slider).find(".owl-carousel");
      $owl.each(function () {
        $(this).owlCarousel({
          items: items,
          loop: true,
          mouseDrag: true,
          lazyLoad: lazyLoad,
          autoplay: autoplay,
          autoplaySpeed: 2000,
          autoplayTimeout: 7000,
          smartSpeed: 2000,
          autoplayHoverPause: true,
          center: true,
          nav: nav,
          navText: [
            '',
            ''
          ],
          dots: dots,
          dotsEach: true
        });
      })
    }
    initOwlSliders(".slider-default", 1, false, true, true, true)

    let bcatMenu = (function() {

      let $desktopMenu = $(".header-nav > ul.level_1");
      let $mobileOpener = $('button.mobile-open');

      let init = function() {
        $mobileOpener.on('click', function () {
          if ($mobileOpener.hasClass('active')) {
            $mobileOpener.removeClass('active');
            $desktopMenu.parent().removeClass('active');
          } else {
            $mobileOpener.addClass('active');
            $desktopMenu.parent().addClass('active');
          }
        });

        if ($body.hasClass('privacy')) {
          return false;
        }

        $desktopMenu.each(function () {
          let $this = $(this);
          let $thisLinks = $this.find('li > a')
          $thisLinks.on('click', function (e) {
            let $this = $(this);
            let thisTitle = $this.attr("class").toLowerCase();
            if (thisTitle !== 'last') {
              e.preventDefault();
              let target = $('.mod_article.' + thisTitle)
              $this.addClass('active');
              $thisLinks.not($this).removeClass('active');
              $('html, body').animate({
                scrollTop: target.offset().top - 80
              }, 800);
            }
            if ($window.width() < 768) {
              $mobileOpener.removeClass('active');
              $desktopMenu.parent().removeClass('active');
            }
          });

          $window.on('scroll',function () {
            let y = $(this).scrollTop();
            $thisLinks.each(function () {
              let $this = $(this);
              let thisTitle = $this.attr("class").toLowerCase();
              if (thisTitle !== 'last') {
                let target = $('.mod_article.' + thisTitle)
                if (target.length > 0) {
                  if (y >= target.offset().top - 80) {
                    $thisLinks.not(this).removeClass('active');
                    $(this).addClass('active');
                  } else if (y === 0) {
                    $thisLinks.removeClass('active');
                  }
                }
              }
            });

          });
        });
      }

      return {
        init: init
      }
    })()
    bcatMenu.init()

  })
// })(window.jQuery, window, document, window.colorbox)
