import $ from 'jquery';
//SCREEN TOOLS
var screenTools = {}
screenTools.detectScreen = function(bp) {
  var currentWidth = $(window).outerWidth()
  var breakpoint = bp || 992
  if (currentWidth > breakpoint) {
    return false
  } else {
    return true
  }
}
screenTools.detectMac = function() {
  var isMac = /mac/i.test(navigator.platform)
  if (isMac) {
    return true
  } else {
    return false
  }
}
screenTools.detectIOSMobile = function() {
  var userAgent = navigator.userAgent || navigator.vendor
  var isIOSMobile = userAgent.match(/(iPod|iPhone|iPad)/i)
  if (isIOSMobile) {
    return true
  } else {
    return false
  }
}
screenTools.detectAndroid = function() {
  var userAgent = navigator.userAgent || navigator.vendor
  var isAndroid = userAgent.match(/Android/i)
  if (isAndroid) {
    return true
  } else {
    return false
  }
}

//WINDOW TOOLS
var windowTools = {}
windowTools.detectResize = function() {
  // Wait for resize end
  $(window).resize(function() {
    if (this.resizeTO) clearTimeout(this.resizeTO)
    this.resizeTO = setTimeout(function() {
      $(this).trigger("resizeEnd")
    }, 500)
  })
}

export {windowTools}
export {screenTools}
