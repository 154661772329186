// import Vue from "vue";

import * as tools from "../utils/utils";

import "../../assets/js/dse_scripts.js";

import "../scss/frontend.scss";

var bcat = {};
bcat.screenTools = tools.screenTools;
bcat.windowTools = tools.windowTools;
window.bcat = bcat;
